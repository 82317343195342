
















import './scss/BaseBoard.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'BaseBoard',
})
export default class BaseBoard extends Vue {
    @Prop({}) items!: any[];
}
